import type GiftTemplate from '@consumer/types/GiftTemplate'
import { formatCurrency } from '@consumer/utils/currency'
import { isEmpty } from '@consumer/helpers/object'
import { capitalize } from '@consumer/helpers/string'
import type { MaybeRefOrGetter } from 'vue'
import { RedemptionMethod } from '@consumer/types/RedemptionMethod'
import { parseDate } from '@consumer/utils/date'
import { addBusinessDays, addDays } from 'date-fns'
import { getTagline, generateDefaultTagline } from '@consumer/logic/giftly'

export const ANONYMOUS_SENDER_NAME = 'Anonymous'
export const ANONYMOUS_RECIPIENT_NAME = 'Gift Recipient'

export enum GiftState {
  INITIALIZED = 'initialized',
  PURCHASE_FLAGGED = 'purchase_flagged',
  PURCHASE_DENIED = 'purchase_denied',
  PURCHASED = 'purchased',
  DELIVERED = 'delivered',
  ACTIVATED = 'activated',
  USED = 'used',
  REDEMPTION_REQUESTED = 'redemption_requested',
  REDEMPTION_REJECTED = 'redemption_rejected',
  REDEEMED = 'redeemed',
  REFUNDED = 'refunded',
  CANCELLED = 'cancelled',
}

export enum GiftRedemptionConfirmationStatus {
  CONFIRMED = 'confirmed',
  EXPIRED = 'expired',
  PENDING = 'pending',
  RESET = 'reset',
}

export const useGift = (maybeGiftRef: MaybeRefOrGetter<GiftTemplate | undefined>) => {
  const giftRef = toRef(maybeGiftRef)
  const gift = $(giftRef)

  const formattedAmount = computed(() => {
    if (gift && gift.amount && /^\d+$/.test(gift.amount.toString())) {
      return formatCurrency(gift.amount)
    }
    else {
      return gift?.amount
    }
  })

  const theItem = computed(() => {
    const { itemDescription, giftItem, item } = gift || {}

    const text = itemDescription
      || (giftItem?.article && `${giftItem.article} ${giftItem.sentenceName}`)
      || giftItem?.sentenceName
      || item
      || 'anything'

    return text === 'default-G' ? 'anything' : text
  })

  function preposition () {
    return gift?.places?.[0]?.giftlyPreposition || 'at'
  }

  const theItemAt = computed(() => `${theItem.value} ${preposition()}`)
  const TheItemAt = computed(() => `${capitalize(theItem.value)} ${preposition()}`)
  const tagline = computed(() => gift && (getTagline(gift) || generateDefaultTagline(gift)))

  const placeCount = computed(() => gift?.places?.length ?? 1)

  const placeName = computed(() => {
    const place = gift?.places?.[0]
    return place?.name || 'Any Place'
  })

  const allPlaceNames = computed(() => gift?.places?.map(place => place.name).join(', '))

  const allPlaceNamesOr = computed(() => {
    const names = gift?.places?.map(place => place.name) ?? []
    return [names.slice(0, -1).join(', '), names.slice(-1)].filter(Boolean).join('or')
  })

  const hasMultiplePlaces = computed(() => placeCount.value > 1)

  // Greeting card
  const greetingCardHasCustomMessage = computed(() => !isEmpty(gift?.greetingCard?.customMessage?.trim()))
  const greetingCardHasSenderName = computed(() => (
    gift?.greetingCard?.senderName && gift?.greetingCard?.senderName !== ANONYMOUS_SENDER_NAME
  ))
  const greetingCardHasRecipientName = computed(() => (
    gift?.greetingCard?.recipientName && gift?.greetingCard?.recipientName !== ANONYMOUS_RECIPIENT_NAME
  ))

  // States
  const isDelivered = computed(() => gift?.state === GiftState.DELIVERED)
  const isActivated = computed(() => gift?.state === GiftState.ACTIVATED)
  const isRedeemed = computed(() => gift?.state === GiftState.REDEEMED)
  const isRedemptionRequested = computed(() => gift?.state === GiftState.REDEMPTION_REQUESTED)
  const isCancelled = computed(() => gift?.state === GiftState.CANCELLED)
  const isRefunded = computed(() => gift?.state === GiftState.REFUNDED)
  const isCancelledOrRefunded = computed(() => isCancelled.value || isRefunded.value)

  // Redemption Confirmation Status
  const redemptionConfirmationIsConfirmed = computed(() => (
    gift?.redemptionConfirmationStatus === GiftRedemptionConfirmationStatus.CONFIRMED
  ))
  const redemptionConfirmationIsExpired = computed(() => (
    gift?.redemptionConfirmationStatus === GiftRedemptionConfirmationStatus.EXPIRED
  ))

  const hasThankYouCard = computed(() => Boolean(gift?.thankYouCard))

  const redemptionExpectedArrivalDate = computed(() => {
    if (!gift?.redemptionCreditSentAt) {
      return null
    }
    else if (gift.redemptionProcessorName === RedemptionMethod.ACH) {
      return addBusinessDays(parseDate(gift.redemptionCreditSentAt), 3)
    }
    else if (gift.redemptionProcessorName === RedemptionMethod.PayPal) {
      return addBusinessDays(parseDate(gift.redemptionCreditSentAt), 4)
    }
    else if (gift.redemptionProcessorName === RedemptionMethod.PerfectGift) {
      return addDays(parseDate(gift.redemptionCreditSentAt), 14)
    }
  })

  return {
    gift: giftRef,
    formattedAmount,
    theItem,
    theItemAt,
    TheItemAt,
    tagline,
    placeCount,
    placeName,
    allPlaceNames,
    allPlaceNamesOr,
    hasMultiplePlaces,
    greetingCardHasCustomMessage,
    greetingCardHasSenderName,
    greetingCardHasRecipientName,
    isDelivered,
    isActivated,
    isRedemptionRequested,
    isRedeemed,
    isCancelled,
    isRefunded,
    isCancelledOrRefunded,
    redemptionConfirmationIsConfirmed,
    redemptionConfirmationIsExpired,
    redemptionExpectedArrivalDate,
    hasThankYouCard,
  }
}
